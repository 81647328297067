import { SsidChart as LineChartIcon, TableChartOutlined as TableIcon } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, IconButton as MuiIconButton, Typography, useTheme } from '@mui/material';
import { BarDatum } from '@nivo/bar';
import { Datum, Line, LineProps, Serie, SliceTooltipProps } from '@nivo/line';
import { useCanAccess } from '@react-admin/ra-rbac';
import * as dateFns from 'date-fns';
import dayjs from 'dayjs';
import { reverse, sumBy } from 'lodash';
import { default as queryString } from 'query-string';
import { FC, useMemo, useState } from 'react';
import { Loading, useRedirect } from 'react-admin';
import { makeLineChartSliceTooltip, makeLineChartTooltip } from './DashboardTooltip';
import { ChartProps, elevation, OnlyNumbers, useDashboardSearchParams } from './DashboardWidget';
import { useUserPreference } from './UserPreferences';
import useColorPalettes from './use-color-palettes';
import { useOrgDateMarkers } from './use-markers';
import useSummary from './use-summary';
import { DashboardDatagrid } from './DashboardDatagrid';
import { TextStyle } from '@nivo/core';
import { DashboardInfo } from './DashboardInfo';

interface AppointmentTrendDatum extends BarDatum {
  date: string;
  md: string;
  ymd: string;
  total: number;  // appointments
  'Arrived': number;
  'Booked': number;
  'Cancel': number;
  'Cancelled': number;
  'Checked-in': number;
  'No Show': number;
  'Pending': number;
}
export interface AppointmentTrendLineDatum extends Datum {
  y: number | null;
  ymd: string;
  md: string;
  total: number;
}
export interface AppointmentTrendLinePoint extends Serie {
  data: AppointmentTrendLineDatum[];
}

export type LineLegendProps = NonNullable<LineProps[ 'legends' ]>[ 0 ];

export const DashboardAppointmentTrend: FC<ChartProps> = ( { date } ) => {
  const title = 'Appointment Trend';
  const theme = useTheme();
  const redirect = useRedirect();
  const { canAccess: canAccessSystem } = useCanAccess( { action: 'manage', resource: 'system' } );
  const isSmall = false;
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();
  const defaultDurationMonths = 6;
  const cacheKey = 'dashboard-appointment-trend';
  const [ showDatagrid, setShowDatagrid ] = useState( false );
  const keys: ( keyof OnlyNumbers<AppointmentTrendDatum> )[] = [ 'Cancelled', 'No Show' ];
  const { isReportView, trendMonths } = useDashboardSearchParams();

  const durationMonths = useMemo( () => {
    if( trendMonths ) return trendMonths;
    if( isLoadingPreferences || !preferences?.dashboardTrendMonths ) return defaultDurationMonths;
    return preferences.dashboardTrendMonths as number;
  }, [ trendMonths, preferences, isLoadingPreferences ] );

  // const counts = useMemo( () => (
  //   Object.fromEntries( data.map( ( { id, data } ) => [ id, data.reduce( ( tot, d ) => ( d.y || 0 ) + tot, 0 ) ] ) )
  // ), [ data ] );
  // const yMin = useMemo( () => Math.min( ...[ yMax * 0.9, ...data.flatMap( row => row.data.map( d => d.y || 0 ) ) ] ), [ data ] );

  const summary = useSummary<AppointmentTrendDatum[]>( 'appointmentTrend', { date, months: durationMonths } );

  const data = useMemo( (): AppointmentTrendLinePoint[] => keys.map( id => ( {
    id, data: summary.data?.map( d => {
      const { ymd, md, date, total } = d;
      // const y = d[ id ] >= 1 ? d[ id ] : null; // no zeros for log scale
      const y = d[ id ];
      return { x: ymd, y, ymd, md, date, total };
    } )
      .filter( d => !!d.y )
      .sort( ( a, b ) => a.ymd < b.ymd ? -1 : 1 ) || [],
  } ) ), [ date, keys, summary.data ] );

  const isEmpty = useMemo( () => sumBy( data, d => d.data.length ) === 0, [ data ] );
  const hideCurrentMonth = useMemo( () => !date && new Date().getDate() <= 7, [ date ] );

  const lineData = useMemo( () => {
    if( isEmpty ) return [];
    const ymd = dayjs().startOf( 'month' ).format( 'YYYY-MM-DD' );
    if( hideCurrentMonth ) {
      return data.map( d => ( {
        ...d,
        data: d.data.filter( p => !( p.x === ymd ) ),
      } ) );
    }
    return data;
  }, [ data, hideCurrentMonth, isEmpty ] );

  const xMax = useMemo( () => date ? date.toDate() : hideCurrentMonth ? dayjs().startOf( 'month' ).subtract( 1, 'second' ).toDate() : new Date(), [ date, hideCurrentMonth ] );
  const xMin = useMemo( () => date ? dateFns.addMonths( date.toDate(), 0 - durationMonths ) : dateFns.addMonths( new Date(), 0 - durationMonths ), [ date, durationMonths ] );
  const yMax = useMemo( () => Math.max( ...[ 10, ...lineData.flatMap( row => row.data.map( d => d.y || 0 ) ) ] ), [ lineData ] );

  const colorPalettes = useColorPalettes();
  const colors = useMemo( () => reverse( [ ...colorPalettes.discretePrimary.slice( 0, keys.length ) ] ), [ keys, colorPalettes ] );

  const markers = useOrgDateMarkers( { date, duration: { type: 'months', count: durationMonths } } );

  return (
    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title={ isReportView ?
          title :
          <Box sx={ { display: 'flex' } } >
            <Typography>{ title }</Typography>
            <DashboardInfo tag='appointment-trend'>
              <i>{ title }</i> shows the percentage rate or No Show and Cancelled appointments over a period of time.
              This percentage derived from the total number of appointments over that period of time.
              Use the <TableIcon fontSize='small' sx={{ verticalAlign: 'bottom' }}/> Data View button to show the numerical data.
            </DashboardInfo>
            <Box sx={ { flexGrow: 1 } } />
            <MuiIconButton
              sx={ {
                // paddingTop: 0, paddingBottom: 0,
                marginTop: '-8px',
                marginBottom: '-8px',
              } }
              onClick={ () => setShowDatagrid( !showDatagrid ) }
            >
              { showDatagrid ? <LineChartIcon /> : <TableIcon /> }
            </MuiIconButton>
          </Box>
        }
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `${ durationMonths } Months Ending ${ date.format( 'MMM YYYY' ) }` : `Previous ${ durationMonths } Months` }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
            // fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        { summary.isLoading
          ? <Box>
            <Loading
              loadingPrimary=''
              loadingSecondary=''
              sx={ {
                '@media (min-width: 0)': {
                  marginTop: 0,
                  marginBottom: 7,
                  height: '100%',
                  width: 500,
                  minHeight: 400,
                }
              } }
            />
          </Box>

          :
          <Box
            sx={ {
              position: 'relative',
            } }
          >
            { showDatagrid
              ? (
                <DashboardDatagrid
                  rows={ ( summary.data || [] ).map( ( d, id ) => ( { id, ...d } ) ) }
                  columns={ keys }
                  exportFileName={ cacheKey }
                />
              )
              : <>

                <Line
                  width={ 500 }
                  height={ 400 }
                  curve='monotoneX'
                  data={ lineData }
                  margin={ { top: 50, right: 50, bottom: 55, left: 75 } }
                  gridYValues={ 4 }

                  isInteractive={ !isEmpty }
                  useMesh={ !isEmpty }
                  enableCrosshair
                  // enableArea
                  areaOpacity={ 0.1 }
                  lineWidth={ 3 }
                  pointSize={ 6 }

                  xFormat="time:%Y-%m"
                  xScale={ {
                    type: 'time',
                    format: '%Y-%m-%d',
                    precision: 'month',
                    useUTC: false,
                    max: xMax,
                    min: xMin,
                  } }

                  // yFormat={ ( value: number | undefined, context: AppointmentTrendLineDatum ): string => {
                  //   return !value ? '' : `${ value.toString() }%`;
                  // } }
                  // @ts-ignore: 2769
                  yFormat={ ( value: number | undefined ): string => {
                    return !value ? '' : `${ value.toString() }%`;
                  } }
                  // yFormat='>-1.1~f'
                  yScale={ {
                    type: 'linear',
                    // base: 2,
                    max: Math.ceil( yMax / 5 ) * 5,
                    min: 0,
                  } }

                  enableSlices='x'
                  sliceTooltip={ makeLineChartSliceTooltip( {
                    theme,
                    renderHeader: AppointmentTrendSliceTooltipHeader
                  } ) }

                  tooltip={ makeLineChartTooltip( theme ) }

                  markers={ markers }

                  onClick={ ( point ) => {
                    if( !canAccessSystem ) return;
                    // @ts-ignore:2339,2352
                    const { serieId: id, data: { ymd } } = point as AppointmentTrendLinePoint;  // action: 'confirm', date: '5/31'
                    if( !ymd ) return;
                    const filter = JSON.stringify( {
                      action: typeof id == 'string' ? id.toLowerCase() : undefined,
                      // createdAt: [ `gte:${ ymd }`, `lte:${ ymd }` ].join( ';' ),
                      createdAt: ymd,
                      delimiter: ';',
                    } );
                    redirect( `/actionlogs?${ queryString.stringify( { filter } ) }` );
                  } }

                  theme={ {
                    background: theme.palette?.background?.default,
                    axis: {
                      legend: { text: { fontSize: isSmall ? 16 : 18 } },
                      ticks: { text: { fontSize: isSmall ? 0 : 14 } },
                    },
                    labels: { text: { fill: '#333' } },
                    legends: {
                      text: { fontSize: 14 },
                      hidden: {
                        symbol: { fill: theme.palette?.mode === 'dark' ? '#ccc' : '#333' },
                        text: { fill: theme.palette?.mode === 'dark' ? '#ccc' : '#333' } as TextStyle,
                      },
                    },
                    text: { fill: theme.palette?.mode === 'dark' ? '#ccc' : '#333' },
                    tooltip: {
                      container: {
                        borderRadius: '8px',
                        border: `0.5px solid ${ theme.palette?.grey?.A400 ?? 'grey' }`,
                        boxShadow: `${ theme.palette?.grey?.A400 ?? 'grey' } 0px 2px 2px`,
                        backgroundColor: theme.palette?.background?.default,
                        color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
                        padding: '5px 9px',
                      },
                    },
                  } }

                  colors={ isEmpty ? [ theme.palette?.mode === 'dark' ? '#555' : '#ccc' ] : colors } //{ scheme: colors, size: keys.length } }
                  // enableLabel={ false }
                  enableGridX={ false }
                  enableGridY={ true }
                  axisTop={ null }
                  axisRight={ null }
                  axisLeft={ {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: 4, // [ 0, 1000, 2000, 3000 ],
                    legend: 'Monthly percentage',
                    legendPosition: 'middle',
                    legendOffset: -67

                  } }

                  axisBottom={ {
                    tickSize: 5,
                    // ticksPosition: 'before',
                    tickPadding: 5,
                    format: '%b',
                    tickValues: `every ${ Math.ceil( durationMonths / 9 ) } months`,
                    legend: 'Appointment Month',
                    legendPosition: 'middle',
                    legendOffset: 42,
                  } }

                  legends={ [ {
                    anchor: 'top-left',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: -40,
                    itemsSpacing: 65,
                    itemWidth: 80,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 1, // 0.95,
                    symbolSize: 18, // 20,
                    symbolShape: 'circle', // 'square',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1
                        }
                      }
                    ],
                    toggleSerie: true,
                  } ] }
                />
              </>
            }
          </Box>
        }
      </CardContent>
    </Card >

  );

}

export const AppointmentTrendSliceTooltipHeader: FC<SliceTooltipProps> = ( { slice } ) => {
  const [ point ] = slice.points;
  const {
    xFormatted,  // 2024-08
    // @ts-ignore: 2339
    ymd, total,
  } = point.data;
  const title = `${ new Date( ymd ).toUTCString().slice( 8, 11 ) } ${ xFormatted.toString().slice( 0, 4 ) }`; // Aug 2024

  return (
    <>
      <Box
        key='h0'
        className='tooltip-row'
      >
        <Typography sx={ { fontWeight: 'bold' } }>{ title } </Typography>
      </Box>
      <Box
        key='h1'
        className='tooltip-row'
      >
        <Typography>Appointments: </Typography>
        <Typography sx={ { fontWeight: 'bold' } } >{ total } </Typography>
      </Box>
    </>
  );
}
