import { SsidChart as LineChartIcon, TableChartOutlined as TableIcon } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, IconButton as MuiIconButton, Typography, useTheme } from '@mui/material';
import { GridColType } from '@mui/x-data-grid-premium';
import { BarDatum } from '@nivo/bar';
import { TextStyle } from '@nivo/core';
import { Datum, Line, Serie } from '@nivo/line';
import * as dateFns from 'date-fns';
import dayjs from 'dayjs';
import { sumBy } from 'lodash';
import { FC, useMemo, useState } from 'react';
import { Loading } from 'react-admin';
import { DashboardDatagrid } from './DashboardDatagrid';
import { makeLineChartSliceTooltip, MonthlyLineChartSliceTooltipHeader } from './DashboardTooltip';
import { ChartProps, elevation, OnlyNumbers, useDashboardSearchParams } from './DashboardWidget';
import { useUserPreference } from './UserPreferences';
import useColorPalettes from './use-color-palettes';
import { useOrgDateMarkers } from './use-markers';
import useSummary from './use-summary';
import { formatCurrency } from './DashboardValueDelivered';
import { DashboardInfo } from './DashboardInfo';

export interface RecoverableRevenueTrendDatum extends BarDatum {
  date: string;
  md: string;
  ymd: string;
  'Appointments Billing Audit': number;
  'Unfinished Appointments': number;
  total: number;
}
export interface RecoverableRevenueTrendLineDatum extends Datum {
  y: number | null;
  ymd: string;
  md: string;
}
export interface RecoverableRevenueTrendLinePoint extends Serie {
  data: RecoverableRevenueTrendLineDatum[];
}

export const DashboardRecoverableRevenueTrend: FC<ChartProps> = ( { date } ) => {
  const title = 'Recoverable Revenue Trend';
  const theme = useTheme();
  const isSmall = false;
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();
  const defaultDurationMonths = 6;
  const cacheKey = 'dashboard-recoverable-revenue-trend';
  const [ showDatagrid, setShowDatagrid ] = useState( false );
  const { isReportView, trendMonths } = useDashboardSearchParams();
  const keys: ( keyof OnlyNumbers<RecoverableRevenueTrendDatum> )[] = [ 'Appointments Billing Audit', 'Unfinished Appointments' ];

  const durationMonths = useMemo( () => {
    if( trendMonths ) return trendMonths;
    if( isLoadingPreferences || !preferences?.dashboardTrendMonths ) return defaultDurationMonths;
    return preferences.dashboardTrendMonths as number;
  }, [ preferences, isLoadingPreferences, trendMonths ] );

  const summary = useSummary<RecoverableRevenueTrendDatum[]>( 'recoverableRevenueTrend', { date, months: durationMonths } );

  const hideCurrentMonth = useMemo( () => !date && new Date().getDate() <= 7, [ date ] );

  const gridData = useMemo( () => summary.data?.map( ( d, id ) => {
    const ym = d.ymd.slice( 0, 7 );
    return {
      ...d,
      id,
      ymd: ym,
    };
  } ) || [] , [ date, summary.data ] );

  const chartData = useMemo( (): RecoverableRevenueTrendLinePoint[] => keys.map( id => ( {
    id, data: summary.data?.map( d => {
      const { ymd, md, date } = d;
      const y = d[ id ] >= 1 ? d[ id ] : null; // no zeros for log scale
      // const y = d[ id ];
      return { x: ymd, y, ymd, md, date };
    } )
      .filter( d => !!d.y )
      .filter( d => !( hideCurrentMonth && d.ymd == dayjs().startOf( 'month' ).format( 'YYYY-MM-DD' ) ) )
      .sort( ( a, b ) => a.ymd < b.ymd ? -1 : 1 ) || [],
  } ) ), [ date, keys, summary.data ] );

  const isEmpty = useMemo( () => sumBy( chartData, d => d.data.length ) === 0, [ chartData ] );

  const xMax = useMemo( () => date ? date.toDate() : hideCurrentMonth ? dayjs().startOf( 'month' ).subtract( 1, 'second' ).toDate() : new Date(), [ date, hideCurrentMonth ] );
  const xMin = useMemo( () => date ? dateFns.addMonths( date.toDate(), 0 - durationMonths ) : dateFns.addMonths( new Date(), 0 - durationMonths ), [ date, durationMonths ] );
  const yMax = useMemo( () => Math.max( ...[ 10, ...chartData.flatMap( row => row.data.map( d => d.y || 0 ) ) ] ), [ chartData ] );

  const colorPalettes = useColorPalettes();
  const colors = useMemo( () => [
    colorPalettes.discretePrimary[ 4 ], // blue
    colorPalettes.discretePrimary[ 5 ], // purple
    colorPalettes.discretePrimary[ 2 ], // red
    colorPalettes.discretePrimary[ 6 ], // green
  ], [ colorPalettes, keys ] );

  const markers = useOrgDateMarkers( { date, duration: { type: 'months', count: durationMonths } } );

  return (
    ( <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        height: '100%',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title={ isReportView ?
          title :
          <Box sx={ { display: 'flex' } } >
            <Typography>{ title }</Typography>
            <DashboardInfo tag='%C2%A0-4'>
              <i>{ title }</i> data is based on billing and appointment anomaly data. A downward trend is ideal
              and shows your organization properly closes appointments and encounters and corrects any previously detected anomalies.
            </DashboardInfo>
            <Box sx={ { flexGrow: 1 } } />
            <MuiIconButton
              sx={ {
                // paddingTop: 0, paddingBottom: 0,
                marginTop: '-8px',
                marginBottom: '-8px',
              } }
              onClick={ () => setShowDatagrid( !showDatagrid ) }
            >
              { showDatagrid ? <LineChartIcon /> : <TableIcon /> }
            </MuiIconButton>
          </Box>
        }
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `${ durationMonths } Months Ending ${ date.format( 'MMM YYYY' ) }` : `Previous ${ durationMonths } Months` }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        { summary.isLoading
          ? <Box>
            <Loading
              loadingPrimary=''
              loadingSecondary=''
              sx={ {
                '@media (min-width: 0)': {
                  marginTop: 0,
                  marginBottom: 7,
                  height: '100%',
                  width: 500,
                  minHeight: 400,
                }
              } }
            />
          </Box>

          :
          <Box
            sx={ {
              position: 'relative',
            } }
          >
            { showDatagrid
              ? (
                <DashboardDatagrid
                  rows={ gridData }
                  columns={ [ ...keys, 'total' ].map( field => ( {
                    field,
                    type: 'number' as GridColType,
                    valueFormatter: formatCurrency,
                  } ) ) }
                  exportFileName={ cacheKey }
                />
              )
              : <>

                <Line
                  width={ 500 }
                  height={ 400 }
                  curve='monotoneX'
                  data={ isEmpty ? [] : chartData }
                  margin={ { top: 60, right: 50, bottom: 55, left: 75 } }
                  gridYValues={ 4 }

                  isInteractive={ !isEmpty }
                  useMesh={ !isEmpty }
                  enableCrosshair
                  // enableArea
                  areaOpacity={ 0.1 }
                  lineWidth={ 3 }
                  pointSize={ 6 }

                  xFormat="time:%Y-%m"
                  xScale={ {
                    type: 'time',
                    format: '%Y-%m-%d',
                    precision: 'month',
                    useUTC: false,
                    max: xMax,
                    min: xMin,
                  } }

                  yScale={ {
                    type: 'log',
                    base: 2,
                    max: yMax * 1.5,
                    min: 1,
                  } }
                  yFormat=">-$,.2~f"

                  enableSlices='x'
                  sliceTooltip={ makeLineChartSliceTooltip( {
                    theme,
                    renderHeader: MonthlyLineChartSliceTooltipHeader
                  } ) }

                  theme={ {
                    background: theme.palette?.background?.default,
                    axis: {
                      legend: { text: { fontSize: isSmall ? 16 : 18 } },
                      ticks: { text: { fontSize: isSmall ? 0 : 14 } },
                    },
                    labels: { text: { fill: '#333' } },
                    legends: {
                      text: { fontSize: 14 },
                      hidden: {
                        symbol: { fill: theme.palette?.mode === 'dark' ? '#ccc' : '#333' },
                        text: { fill: theme.palette?.mode === 'dark' ? '#ccc' : '#333' } as TextStyle,
                      },
                    },
                    text: { fill: theme.palette?.mode === 'dark' ? '#ccc' : '#333' },
                    tooltip: {
                      container: {
                        borderRadius: '8px',
                        border: `0.5px solid ${ theme.palette?.grey?.A400 ?? 'grey' }`,
                        boxShadow: `${ theme.palette?.grey?.A400 ?? 'grey' } 0px 2px 2px`,
                        backgroundColor: theme.palette?.background?.default,
                        color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
                        padding: '5px 9px',
                      },
                    },
                  } }

                  colors={ isEmpty ? [ theme.palette?.mode === 'dark' ? '#555' : '#ccc' ] : colors } //{ scheme: colors, size: keys.length } }
                  // enableLabel={ false }
                  enableGridX={ false }
                  enableGridY={ true }
                  axisTop={ null }
                  axisRight={ null }
                  axisLeft={ {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: 4, // [ 0, 1000, 2000, 3000 ],
                    legend: 'Monthly $ loss (log2)',
                    legendPosition: 'middle',
                    legendOffset: -67

                  } }

                  axisBottom={ {
                    tickSize: 5,
                    // ticksPosition: 'before',
                    tickPadding: 5,
                    format: '%b',
                    tickValues: `every ${ Math.ceil( durationMonths / 9 ) } months`,
                    legend: 'Month',
                    legendPosition: 'middle',
                    legendOffset: 42,
                  } }

                  markers={ markers }

                  legends={ [ {
                    anchor: 'top-left',
                    direction: 'row',
                    justify: false,
                    translateX: -20,
                    translateY: -40,
                    itemsSpacing: 65,
                    itemWidth: 130,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 1, // 0.95,
                    symbolSize: 18, // 20,
                    symbolShape: 'circle', // 'square',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1
                        }
                      }
                    ],
                    toggleSerie: true,
                  } ] }
                />
              </>
            }
          </Box>
        }
      </CardContent>
    </Card > )
  )
}
